@import "includes/header";
@import "includes/footer";

.cover {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    align-items: center;
    color: #fff;
    height: 420px;
    min-height: 100vh;
    background: url($img-path + 'cover.jpg') no-repeat center #404040 fixed;
    background-size: cover;
    padding: 120px 20px 70px;

    .container {max-width: 860px;}
    .subtitle {
        font: bold 1.5rem $font-barlow-condensed;
        letter-spacing: .2em;
        margin-bottom: 2em;
    }
    .title {margin-bottom: 1.5em;}
    button {
        background: none;

        .icon {
            font-size: 4rem;
            margin-right: 10px;
        }
    }
}
.scroll {
    position: absolute;
    bottom: 0;
    font-size: 1.1rem;
    -webkit-animation: scroll .8s infinite;
    animation: scroll .8s infinite;

    &::after {
        content: '';
        display: block;
        margin: 1em auto 0;
        height: 30px; width: 0;
        border-left: 1px solid;
    }
}

@-webkit-keyframes scroll {
    0% {bottom: 0;}
    50% {bottom: -5px;}
    100% {bottom: 0;}
}

@keyframes scroll {
    0% {bottom: 0;}
    50% {bottom: -5px;}
    100% {bottom: 0;}
}

.product {
    text-align: center;

    .h4 {color: $c-red;}
    p {
        font-size: 2.1rem;
        font-family: $font-barlow-condensed;
    }
}
.schema {
    margin-top: 40px;
    position: relative;
    line-height: 1.125;

    .number {
        color: $c-red;
        font-weight: bold;
    }
    &-wrap {
        position: relative;
        margin: 0 auto 2em;
        max-width: 85%;
        width: 538px;

        img {
            display: block;
            margin-left: -7%;
        }
        .number {position: absolute;}
        .performance {top: -1%; left: 3%;}
        .user-friendly {top: 47%; left: -3%;}
        .customization {right: 0%; top: 6.5%;}
        .flexiconnect {right: 2%; top: 51%;}
        .multisport {right: 35%; top: 85.5%;}
    }
    figcaption {
        text-align: left;
        margin: 1.5em auto 0;
        clear: both;
        max-width: 480px;

        p {font: inherit;}
        h5 {color: $c-red;}
    }
}
.places {
    .container {position: relative;}
    &-slider {
        &:not(.tns-slider) .item:not(:first-child),
        &:not(.tns-slider) &-controls {display: none;}
        &-controls {
            text-align: center;
            margin-top: 10px;

            button {
                width: 60px; height: 50px;
                background: none;
                font-size: 2rem;
                color: inherit;
            }
        }
        img {
            margin-bottom: -2em;
            display: block;
        }
    }
    .item {
        position: relative;
        text-align: center;
    }
    .intro {
        h2 {line-height: .9;}
        p {
            font-size: 1.8rem;
            margin: .5em 0;
        }
    }
    .legend {
        position: relative;
        display: inline-block;
        direction: rtl;
        background: $c-red;
        color: #fff;
        line-height: 62px;
        padding-left: 66px;
        border-radius: 2em;
        text-align: right;
        min-height: 66px;
        margin: 0 1em;
        overflow: hidden;

        .text {
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            padding: 10px 1.5em 10px 10px;
        }
        strong {
            display: block;
            text-transform: uppercase;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%; left: -3px;
            width: 72px; height: 72px;
            background: $c-red;
            margin-top: -36px;
            border-radius: 50%;
        }
        .plus {
            left: 3px; top: 50%;
            margin-top: -30px;
            position: absolute;
            z-index: 1;
        }
    }
    button[data-action="start"],
    button[data-action="stop"] {display: none;}
}
.quotes {
    position: relative;
    background: url($img-path + 'arena1.jpg') no-repeat center #404040;
    background-size: cover;
    color: #fff;
    text-align: center;
    overflow: hidden;

    .skew {
        position: absolute;
        width: 100%; height: 60px;
        left: 0;
        fill: #fff;
    }
    .top {top: -1px;}
    .bottom {bottom: -1px;}
    .container {
        max-width: 880px;
        padding: 60px 0 100px;
    }
    &-frame {
        padding: 30px 30px 94px;
        background: rgba($c-red, .85);
        background: linear-gradient(to bottom, rgba(#df1b1b, .85), rgba(#f32d22, .85));
        border-radius: 4px;
        position: relative;
        line-height: 1.25;

        .bubble {
            fill: #df1b1b;
            width: 71px;
            position: absolute;
            bottom: 100%; left: 50%;
            margin-left: -35.5px;
            opacity: .85;
        }
    }
    button[data-action="start"],
    button[data-action="stop"] {display: none;}
    &-slider {
        &:not(.tns-slider) .item:not(:first-child),
        &:not(.tns-slider) &-controls {display: none;}
        &-controls {
            position: absolute;
            right: 50%; bottom: -64px;
            margin-right: -64px;
            width: 128px; height: 128px;
            border: 2px solid;
            border-radius: 50%;
            overflow: hidden;

            button {
                width: 100%; height: 50%;
                background: none;
                position: relative;
                font-size: 2rem;

                &:first-child {
                    background: #fff;
                    color: $c-red;
                }
            }
        }
    }
    .quote {
        font-size: 2rem;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 1em;
    }
    .author {
        font-weight: bold;
        font-size: 1.8rem;
    }
}
.about {
    .container {max-width: 500px;}
    .h3 {
        text-align: center;
        color: inherit;

        &::after {
            content: '';
            display: block;
            margin: .33em auto 0;
            width: 80px;
            border-top: 2px solid;
        }
    }
    p {margin-bottom: 2em;}
    .cta {width: 100%;}
}
.contact {
    h2 {max-width: 750px;}
    .container {
        position: relative;
    }
    .form-submit {
        text-align: center;
        margin: 0 0 1em 0;
    }
    .legal-text {
        padding-top: .5em;
        border-top: $form-border;
        font-size: 1.3rem;
        color: #757575;

        p {
            font-size: 1rem;
            line-height: 1;
            opacity: .5;
        }
    }
    fieldset {
        max-width: 416px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 480px) {
    .schema-wrap {
        .performance {top: 0%; left: 5%;}
        .user-friendly {top: 48%; left: -1%;}
        .customization {right: 3%; top: 8%;}
        .flexiconnect {right: 5%; top: 52%;}
        .multisport {right: 37%; top: 87%;}
    }
    .quotes-frame {padding: 30px 50px 94px;}
    .contact {
        .form-submit {
            float: right;
            margin: 0 2rem 0 0;
        }
        .legal-text {margin: 55px 140px 0 0;}
    }
}
@media (min-width: 768px) {
    .cover {
        padding-bottom: 110px;
        height: 700px;
    }
    .scroll::after {height: 60px;}
    .product {
        padding-bottom: 40px;

        p {font-size: 2.5rem;}
    }
    .schema {
        margin-top: 80px;

        &-wrap {
            width: 52.54%;

            img {margin-left: -11%;}
        }
        .number {display: none;}
        figcaption {
            width: 29%;
            position: absolute;
            margin: 0;

            &.performance,
            &.user-friendly {text-align: right;}
            &.performance {top: 0; right: 75%; width: 25%;}
            &.user-friendly {top: 48%; right: 76.5%; width: 24%;}
            &.customization {left: 71.25%; top: 8.5%;}
            &.flexiconnect {left: 70.25%; top: 52%; width: 20%;}
            &.multisport {left: 53.25%; top: 87%;width: 25%;}
        }
    }
    .places {
        &-slider-wrapper {
            margin-left: 17%;
        }
        &-slider img {margin: 0;}
        .intro {
            position: absolute;
            z-index: 1;
            top: 40px; left: 0;
            max-width: 100%;

            .line {
                display: inline-block;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    right: -.1em; top: -6px; bottom: .36em; left: 0;
                    background: #fff;
                    z-index: 0;
                }
                span {
                    position: relative;
                    z-index: 1;
                }
            }
            p {
                font-size: 2rem;

                .line::before {top: 0; bottom: .3em;}
            }
        }
        .legend {
            position: absolute;
            bottom: 25px; right: 25px;
            margin: 0;
            transition: max-width 2s linear .5s;
            max-width: 66px;

            .text {
                overflow: hidden;
                white-space: nowrap;
            }
            .plus {
                transition: transform .6s linear .5s;
                transform-origin: center;
            }
        }
        .tns-slide-active .legend {
            max-width: 100%;

            .plus {transform: rotate(-180deg);}

        }
        &-slider-controls {
            margin: 0;
            position: absolute;
            right: 83%; bottom: -10px;

            button {display: block;}
        }
    }
    .quotes {
        padding-left: 80px;
        padding-right: 80px;

        .skew {height: 100px;}
        .container {padding: 100px 0;}
        &-frame {padding: 30px 70px;}
        &-slider-controls {
            right: -64px;
            margin-right: 0;
        }
        .quote {font-size: 2.4rem;}
    }
}
@media (min-width: 1024px) {
    .product {padding-bottom: 60px;}
    .quotes {
        .skew {height: 150px;}
        .container {padding: 150px 0;}
    }
    .contact {
        .fields {
            justify-content: space-between;
            display: flex;
        }
        fieldset {
            width: 48%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
