::-moz-selection {
    color: #fff;
    background-color: #000;
}

::selection {
    color: #fff;
    background-color: #000;
}

button,
.cta {
    font-weight: bold;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #fff;
    background: #000;
    border: none;
    cursor: pointer;
    display: inline-block;
    transition: opacity .2s ease-in-out;
    text-align: center;
    font-size: 1.7rem;

    &:hover {opacity: .75;}
    &:focus {outline: none;}

    img, span {
        vertical-align: middle;
        display: inline-block;
    }
}
.cta {
    font-family: $font-barlow-condensed;
    font-weight: 600;
    padding: 1em;
}
a.cta {color: #fff;}
.icon {
    display: inline-block;
    vertical-align: middle;
    width: 1em; height: 1em;
    margin: 0;
    line-height: 1;

    svg {
        fill: currentColor;
        width: 100%; height: 100%;
        display: block;
    }
}
sup {
    font-family: $font-barlow;
    font-size: .7em;
    position: relative;
    top: -.33em;
    vertical-align: middle;
}
section {
    padding: 40px 20px;
}
.container {
    max-width: 600px;
    margin: 0 auto;
}
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    text-transform: uppercase;
}
h1, h2, h3,
.h1, .h2, .h3 {
    line-height: 1;
    font-weight: bold;
    font-family: $font-teko;
    font-style: normal;
    margin-bottom: .5em;

    sup {font-size: .6em;}
}
h1, .h1 {font-size: 4rem;}
h2, .h2 {
    font-size: 3rem;
    margin-bottom: 0;
}
h3, .h3 {
    font-weight: 500;
    font-size: 2.5rem;
}
h4, .h4 {
    font: italic 600 1.8rem $font-barlow-condensed;
    margin-bottom: 1em;
}
h5, .h5 {
    font-weight: bold;
    font-size: 1.6rem;
}

@media (min-width: 768px) {
    section {padding: 80px 20px;}
    .container {max-width: 768px;}
    h1, .h1 {font-size: 8.1rem;}
    h2, .h2 {font-size: 5.5rem;}
    h3, .h3 {font-size: 3.4rem;}
    h4, .h4 {font-size: 2rem;}
}
@media (min-width: 1025px) {
    .container {max-width: 880px;}
}
@media (min-width: 1280px) {
    .container {max-width: 1024px;}
}

#modal {
    width: 100%; height: 100%;
    position: fixed;
    z-index: 10;
    top: 0; left: 0;
    transition: all .5s ease-in-out;
    display: none;
    background: rgba(#000, .6);
    overflow-y: auto;
    opacity: 0;
    min-width: 320px;

    &.fade {opacity: 1;}
    &.show {display: block;}
}
#modal-close {
    position: absolute;
    right: 0; top: -35px;
    font-size: 3rem;
    background: none;

    .icon {display: block;}
}
#modal-dialog {
    width: 95%;
    max-width: 854px;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
}
#modal-content {
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        border: 0;
    }
}

.js [data-entrance] {visibility: hidden;}

#preloader {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: #fff;
    z-index: 100;
    transition: opacity .5s ease-in-out;

    &.fade {opacity: 0;}
    &.hidden {display: none;}
}
.loader-container .loader {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    margin: auto;
    width: 100px; height: 100px;

    svg {width: 100%; height: 100%;}
    .path1,
    .path2 {
        animation: rotate 4s linear infinite;
        transform-origin: center;
    }
    .path2 {animation-direction: reverse;}
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sticky-contact {
    position: fixed;
    right: 20px; top: 45%;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;

    svg {transition: transform .2s ease-in-out;}
    span {
        display: block;
        transform: rotate(90deg);
        text-shadow: #fff 0 0 5px;
    }
    &:hover svg {transform: translateY(5px);}
}
@media (max-width: 767px) {
    .sticky-contact {
        right: 0;

        svg {width: 39px; height: 60px;}
    }
}
