// Images/Icons
$img-path: "../../images/";

// Fonts
$font-path: "../../fonts/";

$base-font-size: 1.6rem;
$font-teko: 'Teko', sans-serif;
$font-barlow: 'Barlow', sans-serif;
$font-barlow-condensed: 'Barlow Condensed', sans-serif;

// Color
$c-red: #f22c21;

$form-border: .1rem solid rgba(#000, .13);
