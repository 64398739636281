fieldset {
    border: none;
    border-top: $form-border;
    margin: 2em 0 1em;
}
legend {
    text-transform: uppercase;
    text-align: center;
    font: 600 1.4rem $font-barlow-condensed;
    opacity: .25;
    letter-spacing: .1em;
    padding: 0 1em;
    margin: 0 auto;
}
label {
    display: block;
    letter-spacing: .1em;
    text-transform: uppercase;
    font: 600 1.7rem $font-barlow-condensed;
    margin-bottom: .25em;
}
select,
textarea,
input[type="tel"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="email"],
input[type="text"] {
    height: 43px;
    padding: 0 .5em;
    font: inherit;
    border: $form-border;
    background: none;
    width: 100%;
    line-height: normal;
    transition: border .2s ease-in-out;

    &:focus {
        outline: none;
        border-color: currentColor;
    }
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url($img-path + 'angle-down.svg') no-repeat right center;
    padding-right: 2em;

    &::-ms-expand {display: none;}
}
textarea {
    min-height: 9.4rem;
    resize: vertical;
    padding: .5em;
}
button[type="submit"] {
    border-radius: 50%;
    height: 110px; width: 110px;
    font-weight: 600;
    padding: 1em;
    font-family: $font-barlow-condensed;
    position: relative;
    overflow: visible;

    &::after {
        content: url($img-path + 'arrow-submit.svg');
        display: inline-block;
        width: 34px; height: 10px;
        position: absolute;
        right: -20px; top: 50%;
        margin-top: -10px;
        transition: right .2s ease-in-out;
    }
    &:hover::after {right: -30px;}
}

@media (min-width: 480px) {
    .form-row {
        display: flex;
        justify-content: space-between;

        .form-group {width: 48%;}
    }
}

.form-group {
    margin-top: 10px;

    ul {
        color: $c-red;
        font-size: 1rem;
    }
}

.text-error {
    color: $c-red;
    font-weight: 600;
}
.text-success {
    background: #2c9400;
    line-height: normal;
    position: fixed;
    color: #fff;
    z-index: 1;
    padding: .75em 1em .75em 3em;
    border-radius: .4em;
    width: 80%;
    bottom: 40px; left: 10%;
    text-align: center;
    transition: all .3s ease-in-out;
    -webkit-animation: appear-notice .35s cubic-bezier(.59, .5, .32, .97) forwards;
    animation: appear-notice .35s cubic-bezier(.59, .5, .32, .97) forwards;

    &::before {
        content: url("data:image/svg+xml;utf8,<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M0 11l2-2 5 5 11-11 2 2-13 13z' fill='%23fff'></path></svg>");
        display: inline-block;
        width: 20px; height: 20px;
        position: absolute;
        left: 1em; top: 50%;
        margin-top: -10px;
    }
    &.hide {
        bottom: -80px;
        opacity: 0;
    }
}

@-webkit-keyframes appear-notice {
    0% {-webkit-transform: translateY(100%);}
    100% {-webkit-transform: translateY(0);}
}
@keyframes appear-notice {
    0% {transform: translateY(100%);}
    100% {transform: translateY(0);}
}
