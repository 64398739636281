.header {
    position: absolute;
    z-index: 1;
    top: 0; left: 0;
    width: 100%;
    background: #000;
    color: #fff;
    padding: 0 10px;
    line-height: 1;
    text-transform: uppercase;

    a {
        display: inline-flex;
        align-items: center;
        height: 22px;
    }
    &-left,
    &-right {
        align-items: center;
        display: flex;
        padding: 10px 0;
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .baseline {
        font-size: 1rem;
        letter-spacing: .1em;
        font-weight: bold;
        display: inline-block;
        margin: 0 1.5em;
        opacity: .75;
    }
    &-right a {padding: 0 10px;}
    .tel {
        margin: 0 10px 0 auto;
        letter-spacing: .1em;
        font-family: $font-barlow-condensed;
        font-weight: 600;
        padding-right: 1em;
        border-right: 1px solid;

        .icon {
            font-size: 2.1rem;
            margin-right: 7px;
        }
    }
    .mail,
    .fb {font-size: 1.7rem;}
}

@media (min-width: 768px) {
    .header {
        padding: 0 10px 0 20px;

        a {height: 32px;}
        .container {
            flex-wrap: nowrap;
            justify-content: space-between;
            height: 90px;
        }
        .baseline {margin: 0 2em;}
    }
}
