* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
ul {list-style: none;}
img {
    max-width: 100%;
    height: auto;
    border: none;
}
html {font-size: .625em;}
body {
    font-family: $font-barlow;
    font-size: $base-font-size;
    line-height: 1.4;
    color: #000;
    min-width: 320px;
}
a {
    text-decoration: none;

    &,
    &:link,
    &:visited,
    &:hover,
    &:active {color: inherit;}
}
