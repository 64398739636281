.sponsors {
    padding: 40px 20px;

    .container {
        border-top: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 40px;
    }
    img {
        display: block;
        transition: all .2s ease-in-out;

        &:not(:hover) {
            -webkit-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            filter: grayscale(100%);
        }
        &.dollamur:not(:hover) {opacity: .6;}
    }
    a,
    span {
        display: block;

        &:not(:first-child) {margin-left: 3%;}
    }
}

.footer {
    color: #8c8c8c;
    font: 600 1.3rem $font-barlow-condensed;
    letter-spacing: .1em;
    border-top: 1px solid #f0f0f0;
    padding: 10px 20px;
    text-transform: uppercase;

    .container {
        display: flex;
        align-items: center;
    }
    .copyright {flex-grow: 1;}
}

@media (max-width: 767px) {
    .sponsors {
        padding-bottom: 20px;

        .container {padding-top: 20px;}
    }
    .footer {
        padding: 20px;

        .container {
            flex-wrap: wrap;
            justify-content: center;
        }
        .copyright {
            text-align: center;
            order: 3;
            width: 100%;
            margin-top: 1em;
        }
    }
}
@media (max-width: 479px) {
    .footer {
        text-align: center;

        .logo {width: 100%;}
        &-contact {margin-top: 1em;}
    }
}
@media (min-width: 480px) {
    .sponsors {
        a,
        span {
            &:not(:first-child) {margin-left: 6%;}
        }
    }
    .footer-contact {
        position: relative;
        padding-left: 20px;
        margin-left: 20px;

        &::before {
            content: '';
            position: absolute;
            height: 46px; width: 0;
            border-left: 1px solid;
            left: 0; top: 50%;
            margin-top: -23px;
        }
    }
}
